<template>
	<div v-if="instance.fields">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			{{header}}
		</h1>
		<div class="q-mb-md q-pb-md">
			<q-toolbar class="q-mb-sm">
				<q-btn flat icon="keyboard_backspace" label="Back" @click="redirectToTemplateList"/>
				<q-space />

				<q-tabs
					inline-label
					indicator-color="primary"
					active-color="primary"
					narrow-indicator
				>
					<q-route-tab
						:to="{ name: 'Edit RTI Settings' }"
						label="Settings"
						:alert="has_settings_error ? 'negative' : false"
						alert-icon="priority_high"
						name="settings"
					/>
					<q-route-tab
						:to="{ name: 'Edit RTI Fields' }"
						label="Fields"
						:alert="has_fields_error ? 'negative' : false"
						alert-icon="priority_high"
						name="fields"
					/>
				</q-tabs>
			</q-toolbar>
			<div>
				<router-view v-if="ready"></router-view>
			</div>
			<!-- Buttons -->
			<span v-if="instance.id">
				<q-fab
					class="flex justify-end"
					style="position: sticky; bottom: 50px; z-index: 1"
					:label="$q.screen.gt.sm ? 'Actions' : ''"
					label-position="left"
					color="primary"
					icon="keyboard_arrow_up"
					direction="up"
				>
					<q-fab-action
						color="primary"
						icon="delete"
						:label="$q.screen.gt.sm ? 'Delete' : ''"
						@click="openDialog('delete')"
					>
						<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
							Delete
						</q-tooltip>
					</q-fab-action>
					<q-fab-action
						color="primary"
						icon="qr_code"
						:label="$q.screen.gt.sm ? 'Register QR' : ''"
						@click="executeAction(instance, 'register')"
					>
						<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
							Register QR
						</q-tooltip>
					</q-fab-action>
					<q-fab-action
						v-if="instance.registration_type === 'SMS'"
						color="primary"
						icon="qr_code"
						:label="$q.screen.gt.sm ? 'Deregister QR' : ''"
						@click="executeAction(instance, 'deregister')"
					>
						<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
							Deregister QR
						</q-tooltip>
					</q-fab-action>
					<q-fab-action
						color="primary"
						icon="save"
						:label="$q.screen.gt.sm ? 'Save' : ''"
						@click="openDialog('save')"
					>
						<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
							Save
						</q-tooltip>
					</q-fab-action>
				</q-fab>
			</span>
			<span v-else class="field-fab-wrapper">
				<q-btn
					v-if="$q.screen.gt.sm"
					rounded
					icon="save"
					padding="md lg"
					label="Save"
					color="primary"
					@click="openDialog('save')"
				/>
				<q-btn
					v-else
					round
					icon="save"
					size="lg"
					color="primary"
					@click="openDialog('save')"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Save
					</q-tooltip>
				</q-btn>
			</span>
			<q-dialog v-model="confirm_dialog">
				<q-card>
					<q-card-section class="q-pb-none" v-if="!!instance.id && action != 'delete'">
						<div class="text-h6">WARNING</div>
					</q-card-section>
					<q-card-section class="text-center">
						<span>
							{{ dialog_text }}
						</span>
					</q-card-section>
					<q-card-actions align="right">
						<q-btn flat label="Cancel" color="primary" v-close-popup />
						<q-btn
							flat
							label="Confirm"
							color="primary"
							@click="executeAction(instance)"
						/>
					</q-card-actions>
				</q-card>
			</q-dialog>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DatasetsAPI from '@/services/api/Datasets.js'
import RegistrationSettings from './RegistrationSettings.vue'
import RegistrationFields from './RegistrationFields.vue'
import utils from '@/services/utils.js'

export default {
	name: 'RegistrationTemplateInstance',
	components: {
		RegistrationSettings,
		RegistrationFields,
	},
	data() {
		return {
			tab: 'settings',
			confirm_dialog: false,
			instance_index: 0,
			instance: {},
			action: '',
			has_settings_error: false,
			has_fields_error: false,
			warning_text:
				'Making changes to your registration settings will affect all new registrations, ' +
				'including those from QR codes, web sign up pages, ' +
				'and SMS registration numbers which have been issued previously. ' +
				'Are you sure you wish to continue?',
			dialog_text: "",
			tabs: [
				{
					path_name: 'Edit RTI Settings',
					label: 'Settings',
				},
				{
					path_name: 'Edit RTI Fields',
					label: 'Fields',
				}
			],
			ready: false,
		}
	},
	computed: {
		...mapGetters(['registration_template_instances', 'rti_error_message']),
		rti_validation_errors: function () {
			this.has_settings_error = this.has_fields_error = false
			let error_message = this.$store.getters.rti_validation_errors
			let settings_values = [
				'dataset', 'registration_type', 'subdomain', 'selected_numbers', 'registration_timeout'
			]
			if (Object.keys(error_message).length){
				for (let key of Object.keys(error_message)){
					if (settings_values.includes(key)){
						this.has_settings_error = true
					}else(
						this.has_fields_error = true
					)
				}
			}
			return error_message
		},
		header: function () {
			let type = this.$route.params.rti_id === 'add' ? 'Create' : 'Edit'
			return `${type} registration template`
		},
	},
	methods: {
		...mapActions(['saveInstance', 'deleteInstance']),
		openDialog(action) {
			this.confirm_dialog = true
			this.action = action
			let is_create = this.instance.id === 0
			if (action === 'delete') {
				this.dialog_text = 'Are you sure you want to delete this template?'
			} else if (is_create) {
				this.dialog_text = 'Are you sure you want to create a new template?'
			} else if (action === 'save' && !is_create){
				this.dialog_text = this.warning_text
			}
		},
		notifyOnError(error_message) {
			this.$q.notify({
				timeout: 6700,
				message: error_message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		async executeAction(instance, action) {
			if (action) {
				this.action = action
			}
			this.confirm_dialog = false
			this.$q.loading.show({delay: 400})
			switch (this.action) {
				case 'save':
					this.$store.commit('setErrorMessage', {})
					for (let fields of instance.fields){
						if (!fields.enabled){
							fields.prepopulated_value = null
						}
					}
					await this.saveInstance(this.instance_index)
					if (Object.keys(this.rti_validation_errors).length) {
						break
					}
					let state = 'updated'
					if (instance.id == 0) {
						state = 'created'
						await this.$store.dispatch('getRegistrationTemplateInstances')
					}
					this.$q.notify(`The template has been successfully ${state}.`)
					if (state === 'created') {
						this.instance = this.registration_template_instances[0]
						this.$router.push({
							name: 'Edit RTI',
							params: {
								rti_id: this.instance.id,
							},
						})
					}
					this.scrollToTop()
					break
				case 'register':
					await this.registerQR(instance)
					break
				case 'deregister':
					await this.deregisterQR(instance.id)
					break
				case 'delete': {
					await this.deleteInstance(this.instance_index)
					if (!this.rti_error_message) {
						this.$q.notify('The template has been successfully deleted.')
						await this.$store.dispatch('getRegistrationTemplateInstances')
						this.$router.push({ name: 'Dataset Registration' })
					}
					break
				}
				default:
					break
			}
			this.$q.loading.hide()
			if (this.rti_validation_errors.has_required_device_fields) {
				this.notifyOnError(this.rti_validation_errors.has_required_device_fields)
			}
			this.action = ''
		},
		async registerQR(instance) {
			const response = await DatasetsAPI.generateRegisterQRCode(
				instance.id,
				instance.registration_type
			)
			if (response.error) {
				this.notifyOnError(response.error_message)
				return
			}

			utils.directDownloadFile('register', 'png', `data:application/png;base64,${response}`)
		},
		async deregisterQR(instance_id) {
			const response = await DatasetsAPI.generateDeregisterQRCode(instance_id)
			if (response.error) {
				this.notifyOnError(response.error_message)
				return
			}
			utils.directDownloadFile('deregister', 'png', `data:application/png;base64,${response}`)
		},
		async setupInstance() {
			await this.$store.dispatch('getRegistrationTemplateInstances')
			if (!parseInt(this.$route.params.rti_id)) {
				// Create
				await this.$store.commit('addRTI')
			} else {
				// Edit
				this.instance_index =
					this.$store.getters.registration_template_instances.findIndex(
						(it) => it.id === parseInt(this.$route.params.rti_id)
					)
			}
			this.instance =
				this.$store.getters.registration_template_instances[this.instance_index]
			this.ready=true
		},
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		hasSubdomainError(val){
			this.has_subdomain_error = val
		},
		redirectToTemplateList() {
			this.$router.push({ name: "Dataset Registration" })
		}
	},
	created() {
		this.setupInstance()
		this.$store.commit('setErrorMessage', {})
	},
}
</script>
